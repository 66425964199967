import React from 'react';
import classNames from 'classnames';
import { Theme } from '@material-ui/core';

import createGame from './media/create-game.png';
import createGame2x from './media/create-game2x.png';
import createGame3x from './media/create-game3x.png';
import chooseMissions from './media/choose-missions.png';
import chooseMissions2x from './media/choose-missions2x.png';
import chooseMissions3x from './media/choose-missions3x.png';
import trackAction from './media/track-action.png';
import trackAction2x from './media/track-action2x.png';
import trackAction3x from './media/track-action3x.png';
import customBranding from './media/custom-branding.png';
import customBranding2x from './media/custom-branding2x.png';
import customBranding3x from './media/custom-branding3x.png';
import reviewSave from './media/review-save.png';
import reviewSave2x from './media/review-save2x.png';
import reviewSave3x from './media/review-save3x.png';

import styles from './HIWOrganizing.module.scss';
import howItWorksStyles from '../components/HowItWorks.module.scss';
import Page, { Section } from '../../../components/Page';
import Link from '../../../components/Link';
import routes from '../../../routes';
import Image from '../../../components/Image';
import HowItWorksHeaderSection from '../components/HowItWorksHeaderSection';
import Flex from '../../../components/layout/Flex';
import HowItWorksImgColumn from '../components/HowItWorksImgColumn';
import TryItNowCTA from '../../../components/TryItNowCTA';

const HIWOrganizing = () => (
  <Page
    title="How to Organize a Goosechase Experience | Goosechase"
    description="Creating and running a Goosechase Experience is easy and fun! Follow these five simple steps to build your first Experience. Then go build a bunch more!"
  >
    <HowItWorksHeaderSection
      title="How to organize a Goosechase Experience"
      subtitle={
        <span
          css={(theme: Theme) => ({
            display: 'inline-block',
            [theme.breakpoints.up('md')]: { maxWidth: '51.6rem' },
          })}
        >
          Creating and running a Goosechase Experience is easy. And fun! Follow these 5 simple steps to create your
          first.
        </span>
      }
      subtitle2={
        <React.Fragment>
          <Link variant="underline" href={routes.HIW_PLAYING.path} useRouter>
            Go here
          </Link>{' '}
          to learn how to join a Goosechase Experience.
        </React.Fragment>
      }
    />

    <Section padding="none" className={classNames(styles.createGameSection)}>
      <Flex alignItems="center" justifyContent="center" wrap="wrap">
        <div className={howItWorksStyles.column}>
          <h2 className={howItWorksStyles.h2}>Start with the basics</h2>
          <p className={howItWorksStyles.paragraph}>
            Sign up for a free account and start building in the Goosechase Experience Manager. Give your Experience a
            name, photo, and description to mark it as yours. The more unique, the better!
          </p>
          <p className={howItWorksStyles.paragraph}>
            Need help building your Experience? Add co-organizers who'll have the same editing and moderating access you
            do. Many wings make light work!
          </p>
        </div>
        <HowItWorksImgColumn align="center" padLeft padRight>
          <Image
            className={styles.createGameImg}
            src={createGame}
            srcSet={`${createGame2x} 2x, ${createGame3x} 3x`}
            width="415"
            height="516"
            alt="create your game"
          />
        </HowItWorksImgColumn>
      </Flex>
    </Section>

    <Section className={styles.chooseMissionsSection}>
      <Flex alignItems="center" justifyContent="center" wrap="wrap-reverse">
        <HowItWorksImgColumn align="center" padLeft padRight>
          <Image
            className={styles.chooseMissionsImg}
            src={chooseMissions}
            srcSet={`${chooseMissions2x} 2x, ${chooseMissions3x} 3x`}
            width="447"
            height="482"
            alt="choose your missions"
          />
        </HowItWorksImgColumn>
        <div className={classNames(howItWorksStyles.column, howItWorksStyles.columnPadLeft)}>
          <h2 className={howItWorksStyles.h2}>Choose your Missions</h2>
          <p className={howItWorksStyles.paragraph}>
            Did you know Goosechase can be used for{' '}
            <Link href={routes.ONBOARDING_USES.path} variant="underline" useRouter>
              onboarding
            </Link>
            ,{' '}
            <Link href={routes.VIRTUAL_TEAMS_USES.path} variant="underline" useRouter>
              team building
            </Link>
            ,{' '}
            <Link href={routes.K12_EDUCATION_USES.path} variant="underline" useRouter>
              K-12 education
            </Link>
            ,{' '}
            <Link href={routes.CAMPUS_ORIENTATION_USES.path} variant="underline" useRouter>
              campus orientation
            </Link>
            , and{' '}
            <Link href={routes.RECREATION_USES.path} variant="underline" useRouter>
              recreational
            </Link>{' '}
            Experiences? The opportunities are endless.
          </p>
          <p className={howItWorksStyles.paragraph}>
            Each Experience is a collection of Missions for participants to complete for points. Use our bank of
            ready-to-go ideas, or create your own. The weirder the list, the wilder the chase.
          </p>
          <p className={howItWorksStyles.paragraph}>
            You can even automate when Missions appear and disappear throughout the Experience to keep participants on
            their toes! Create endless combinations of tasks with photo/video, text, and GPS options available.
          </p>
        </div>
      </Flex>
    </Section>

    <Section padding="none" className={styles.brandingSection}>
      <Flex alignItems="center" wrap="wrap" justifyContent="center" className={howItWorksStyles.bottomAnchoredContent}>
        <div className={howItWorksStyles.column}>
          <h2 className={howItWorksStyles.h2}>Add custom branding</h2>
          <p className={howItWorksStyles.paragraph}>
            Make it your own! Add a custom "splash screen" for participants to see when first joining the Experience.
            This full-screen image is used to highlight your organization or sponsors, and let your participants know
            they've joined the right one.
          </p>
          <p className={howItWorksStyles.paragraph}>
            Within each Mission, you have the opportunity to include a link and photo for extra context, brand
            awareness, or sponsorship promotion.
          </p>
        </div>
        <HowItWorksImgColumn align="center" padLeft removeBotPadding>
          <Image
            className={styles.customBrandingImg}
            src={customBranding}
            srcSet={`${customBranding2x} 2x, ${customBranding3x} 3x`}
            width="362"
            height="504"
            alt="custom branding"
          />
        </HowItWorksImgColumn>
      </Flex>
    </Section>

    <Section className={styles.trackActionSection}>
      <Flex
        alignItems="center"
        justifyContent="center"
        wrap="wrap-reverse"
        className={howItWorksStyles.bottomAnchoredContent}
      >
        <HowItWorksImgColumn align="center" padRight removeBotPadding>
          <Image
            className={styles.trackActionImg}
            src={trackAction}
            srcSet={`${trackAction2x} 2x, ${trackAction3x} 3x`}
            width="492"
            height="551"
            alt="track the action"
          />
        </HowItWorksImgColumn>
        <div className={classNames(howItWorksStyles.column, howItWorksStyles.columnPadLeft)}>
          <h2 className={howItWorksStyles.h2}>Wings up, it&apos;s go time</h2>
          <p className={howItWorksStyles.paragraph}>
            When your Experience is in flight, you can be as hands on or hands off as you want. Don&apos;t have time to
            monitor the activity? Great, the Experience is built to run seamlessly without any intervention from you.
            Sit back, relax, and wait to find out who wins.
          </p>
          <p className={howItWorksStyles.paragraph}>
            Want more control? With real-time activity feeds and rankings, you can adjust scores, award bonus points, or
            add and delete Missions on the fly. You have the power, if you&apos;re ready to accept it.
          </p>
        </div>
      </Flex>
    </Section>

    <Section className={styles.shareSection}>
      <Flex alignItems="center" justifyContent="center" wrap="wrap">
        <div className={howItWorksStyles.column}>
          <h2 className={classNames(howItWorksStyles.h2, styles.reviewSaveTitle)}>Celebrate, share, repeat</h2>
          <p className={howItWorksStyles.paragraph}>The goose has been chased. The Experience is over. Now what?</p>
          <p className={howItWorksStyles.paragraph}>
            Using the live leaderboard and reporting dashboard, crown the winners and analyze your Experience to see
            which Missions and submissions were a hit, and who your most engaged participants were.
          </p>
          <p className={howItWorksStyles.paragraph}>
            Download submissions and re-live the fun by sharing with your participants after the Experience has ended.
          </p>
        </div>
        <HowItWorksImgColumn align="center" padLeft padRight>
          <Image
            src={reviewSave}
            srcSet={`${reviewSave2x} 2x, ${reviewSave3x} 3x`}
            width="470"
            height="359"
            alt="review and save"
          />
        </HowItWorksImgColumn>
      </Flex>
    </Section>

    <Section padding="sm">
      <TryItNowCTA
        title={
          <>
            Try it now
            <br /> for free
          </>
        }
        className={howItWorksStyles.tryItNowContent}
        desc="Build and run an Experience today. Create as
            many missions as you can muster, invite up to 3 teams, and watch the magic happen."
      />
    </Section>
  </Page>
);

export default HIWOrganizing;
